import { Link } from "react-router-dom"
import logoDark from '../../assets/Logo/logo-mobile-mode-dark.svg'
export default function HeaderDarkTheme() {
    return(
        <header>
            <Link to="/" title="Redirection vers la page principale du Studio Culotté.">
                <img src={logoDark} alt="Logo du Studio Culotté de couleur blanche représentant un 'E' avec un accent en forme d'éclair." />
            </Link>  
        </header>
    )
}