import '../../../css/Navigation/OptionsMenu.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Resize } from "../../Resize/Resize"
import { ThemeContext, themes } from '../../LightMode/ThemeContext'

import pictosLight from "../../../assets/Picto/picto-white-mode.svg"
import pictosNoCSS from "../../../assets/Picto/picto-noCSS-mode.svg"
import pictosDark from "../../../assets/Picto/picto-night-mode.svg"

export default function OptionsMenu(props) {
    const {windowSize} = Resize();
    const [ isNoCSS, isNoCSSOn ] = useState("")
    const deleteCSS = () => {
        isNoCSSOn(isNoCSS === "" ? "active" : "")
        document.body.classList.add("mode-nocss")
        let allImgs = document.querySelectorAll("img")
        allImgs.forEach((img) => {
            img.style.width = "200px"
        })
        document.querySelector("link[rel=stylesheet]").remove();
    }
    const refreshPage = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        const interval = setInterval(() => window.location.reload(false), 750);
        return () => clearInterval(interval)
    }
    const [ darkMode, setDarkMode ] = useState(true)
    
    const [ optionsBg, setOptionsBg ] = useState("active")
    const handleOptionsBg = () => {
        setOptionsBg(optionsBg === "active" ? "" : "active");
    }

    const linkStyle = {
        color: "white",
        textDecoration: "none"
    }

    return(
        <>  
            {windowSize.innerWidth < 1201 ?
                <section onClick={() => {handleOptionsBg(); props.closeOptionsMenu()}} className={`options-bg ${optionsBg}`}></section>
            :
                null
            }
            {isNoCSS === "active" ? 
                null
            :
                <section className={windowSize.innerWidth < 1201 ? "options" : "options desk"}>
                    <ThemeContext.Consumer>
                        {({ changeTheme }) => (
                            <div className="option dark" onClick={() => {setDarkMode(!darkMode); changeTheme(!darkMode ? themes.dark : null); props.closeOptionsMenu()}}>
                                <p>Graphisme sombre</p>
                                <img src={pictosDark} alt="Pictogramme représentant une bulle de couleur bleu sombre signifant que le site Internet peut passer en 'dark mode'" />
                            </div>
                        )}
                    </ThemeContext.Consumer>
                    <ThemeContext.Consumer>
                        {({ changeTheme }) => (
                            <div className="option light" onClick={() => {setDarkMode(!darkMode); changeTheme(darkMode ? themes.light : null); props.closeOptionsMenu()}}>
                                <p>Graphisme clair</p>
                                <img src={pictosLight} alt="Pictogramme représentant une bulle entièrement blanche signifant que le site Internet peut passer en 'light mode'" />
                            </div>
                        )}
                    </ThemeContext.Consumer>
                    <div className="option" onClick={deleteCSS}>
                        <p>Sans graphisme</p>
                        <img src={pictosNoCSS} className={`${isNoCSS}`} alt="Pictogramme représentant une bulle avec le mot 'CSS' barré signifant que le site Internet peut passer en 'no css mode'" />
                    </div>
                </section>
            }
            {isNoCSS === "active" ? 
                <p onClick={refreshPage} className="noCSS" style={{backgroundColor:"#000022",width:"325px",margin:"calc(0% - 8px)",padding:"2rem",position:"fixed",top:0,textAlign:"center",fontSize:"20px",zIndex:"999"}}><Link style={linkStyle} to="/savoir" title="Redirection vers la page d'accueil en mode 'normal'">Voici le site sans création (scroll pour en voir plus). Cette page est ici pour démontrer notre rôle et le travail de directeur artistique. Partir d’une feuille blanche et de quelques éléments, les hiérarchisé, leur donner corps, structure et cohérence. Pour revenir en mode 'normal', cliquez sur ce paragraphe.</Link></p>
            : 
                null
            }
        </>
    )
}