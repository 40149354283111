import '../../css/Savoir-faire/Savoir.css';
import { Resize } from "../../components/Resize/Resize"
import Savoir from "../../components/Savoir-faire/Savoir"
import Competences from "../../components/Savoir-faire/Competences"
import UtilityDesigner from "../../components/Savoir-faire/UtilityDesigner"
import GameSC from "../../components/Savoir-faire/GameSC"

export default function SavoirMain () {
  const {windowSize} = Resize();

  return (
    <>
      <main className={windowSize.innerWidth < 1201 ? "savoir" : "savoir desk"}>
        <h1>Au Studio Culotté, on sait faire tout ça :</h1>
        <h2>Voici un listing des savoir-faire au Studio Culotté. Clique sur celui qui t’intéresse pour plus de détails.</h2>

        <Savoir />

        <Competences />

        <UtilityDesigner />

        <GameSC />
      </main>
    </>
  );
}