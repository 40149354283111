import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Links from "../LinksNavigation/Links"
import logoDeskLight from '../../../assets/Logo/logo-desk-mode-light.svg'
import logoDeskDark from "../../../assets/Logo/logo-desk-mode-dark.svg"
import pictoMenuOpenLight from "../../../assets/Picto/picto-nav-menu-open-mode-light.svg"
import pictoMenuOpenDark from "../../../assets/Picto/picto-nav-menu-open.svg"
import pictoMenuCloseLight from "../../../assets/Picto/picto-nav-menu-close-mode-light.svg"
import pictoMenuCloseDark from "../../../assets/Picto/picto-nav-menu-close.svg"

import { ThemeContext } from '../../LightMode/ThemeContext'

export default function DeskNavigation() {
    const [ setMenu, isMenuOn ] = useState("");

    const handleClick = () => {
        isMenuOn(setMenu === "" ? "active" : "");
    }
    const closeMobileMenu = () => isMenuOn("");

    let location = useLocation();
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [location]);

    const isMenuOpenBlur = () => {
        if(document.querySelector("main") && document.querySelector("footer")){document.querySelector("main").style.filter = "blur(10px)";document.querySelector("footer").style.filter = "blur(10px)";}
    }
    const isMenuCloseBlur = () => {
        if(document.querySelector("main") && document.querySelector("footer")){document.querySelector("main").style.filter = "none";document.querySelector("footer").style.filter = "none";}
    }

    return(
        <>
            <nav className="nav desk">
                <section className="nav-menu-modes">
                    {setMenu === "active" ?
                        <ThemeContext.Consumer>
                            {theme => (
                                theme.whichTheme === "white-content" ? 
                                    <img onClick={handleClick} className="nav-menu active close" src={pictoMenuCloseLight} alt="Pictogramme représentant le menu burger (3 traits horizontaux)" />
                                :
                                    <img onClick={handleClick} className="nav-menu active close" src={pictoMenuCloseDark} alt="Pictogramme représentant le menu burger (3 traits horizontaux)" />
                            )}
                        </ThemeContext.Consumer>
                    :
                        <ThemeContext.Consumer>
                            {theme => (
                                theme.whichTheme === "white-content" ? 
                                    <img onClick={handleClick} className="nav-menu active" src={pictoMenuOpenLight} alt="Pictogramme représentant le menu burger (3 traits horizontaux)" />
                                :
                                    <img onClick={handleClick} className="nav-menu active" src={pictoMenuOpenDark} alt="Pictogramme représentant le menu burger (3 traits horizontaux)" />
                            )}
                        </ThemeContext.Consumer>
                    }
                    <ThemeContext.Consumer>
                    {theme => (
                        theme.whichTheme === "white-content" ? 
                            <Link to="/" title="Redirection vers la page d'accueil du Studio Culotté." onClick={closeMobileMenu}><img className="nav-logo light" src={logoDeskLight} alt="Logo du Studio Culotté en mode vertical" /></Link>
                        : 
                            <Link to="/" title="Redirection vers la page d'accueil du Studio Culotté." onClick={closeMobileMenu}><img className="nav-logo" src={logoDeskDark} alt="Logo du Studio Culotté en mode vertical" /></Link>
                        )}
                    </ThemeContext.Consumer>
                </section>
                <p className="credits">©Le Studio Culotté 2015-2024 • Lyon</p>
            </nav>
            {setMenu === "active" ? <Links isDesk={true} closeMobileMenu={closeMobileMenu} /> : null}
            {setMenu === "active" ? isMenuOpenBlur() : isMenuCloseBlur()}
        </>
    )
}