const dotsConception = [
    {
        id: 1,
        content: "",
        anchor: "#firstAnchor"
    },
    {
        id: 2,
        content: "1",
        anchor: "#vendangesAnchor"
    },
    {
        id: 3,
        content: "2",
        anchor: "#foulageAnchor"
    },
    {
        id: 4,
        content: "3",
        anchor: "#fermentationAnchor"
    },
    {
        id: 5,
        content: "4",
        anchor: "#clarificationAnchor"
    },
    {
        id: 6,
        content: "5",
        anchor: "#elevageAnchor"
    },
    {
        id: 7,
        content: "6",
        anchor: "#miseAnchor"
    },
    {
        id: 8,
        content: "",
        anchor: "#convaincu"
    }
]

export default dotsConception