import '../../css/Homepage/Homepage.css';
import '../../css/Homepage/HomepageLightTheme.css';
import { Link } from 'react-router-dom';
import { Resize } from "../../components/Resize/Resize"
import Realisations from '../../components/Homepage/Realisations'
import FAQ from '../../components/Homepage/Faq'

import imgStudioHomepage from "../../assets/Images/img-bottle-homepage.webp"

export default function Homepage () {
  const {windowSize} = Resize();
  return (
    <>
      <main className={windowSize.innerWidth < 1201 ? null : "desk"}>
        
        <section className="home-shape-img">
          <div className="shape-home"></div>
          <img className="home-btl-header" src={imgStudioHomepage} alt="Représentation d'une création artistique du Studio Culotté montrant une bouteille avec des grappes de raison à l'intérieur." />
        </section>
        <h1 className="home-title">Le Studio Culotté enrichit l'image des vignerons.</h1>
        
        
        <h2 className="home-description">Nous sommes au service des acteurs de la vigne, du vin & des artisans de la boisson fermentée.</h2>
        
        <section className="home-savoir">
          <h3>Nos Savoir-faire</h3>
          <p>Nos savoir-faire résident dans une nuée de compétences. Nous avons énuméré les majeurs et nous les avons liés sur des pages explicatives.</p>
          <Link to="/savoir"><button>Les savoir-faire créatifs</button></Link>
        </section>

        <section className="home-creation">
          <h3>Comment allons-nous collaborer ?</h3>
          <p>Notre démarche de conception peut être comparée à la conception d'une cuvée dans les grandes lignes. Découvre le parallèle que l'on a crée pour toi !</p>
          <Link to="/creation"><button>Notre démarche de création</button></Link>
        </section>

        <Realisations />
        
        <FAQ />

      </main>
    </>
  );
}