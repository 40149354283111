import '../../css/Savoir-faire/Savoir-faire.css';
import '../../css/Savoir-faire/Savoir-faireLightTheme.css';
import { useState } from "react";
import { ThemeContext } from '../LightMode/ThemeContext'
import { HashLink as LinkAnchor } from 'react-router-hash-link';

import datas from "./datas/ListSavoir.json"

import bgSavoirMobileLight from "../../assets/Background/background-constellation-mobile-jour.svg"
import bgSavoirMobileDark from "../../assets/Background/background-constellation-mobile-nuit.svg"
import bgSavoirTabletLight from "../../assets/Background/background-constellation-tablet-jour.svg"
import bgSavoirTabletDark from "../../assets/Background/background-constellation-tablet-nuit.svg"
import bgSavoirDeskLight from "../../assets/Background/background-constellation-desktop-jour.svg"
import bgSavoirDeskDark from "../../assets/Background/background-constellation-desktop-nuit.svg"
import searchIconLight from "../../assets/Picto/picto-search-mode-light.svg"
import searchIconDark from "../../assets/Picto/picto-search.svg"

export default function Savoir() {
    
    const [ inputText, setInputText ] = useState("");
    const [ errorInput , setErrorInput] = useState(false);
    
    const inputLower = (e) => {
        let lower = e.target.value.toLowerCase();
        if(lower.match("^[A-zÀ-ú _]*$") != null){
            setInputText(lower);
            setErrorInput(false)
        } else {
            setErrorInput(true)
        }
    };

    const filteredDataWeb = datas[0].Web.filter((e) => {
        return(
            inputText === '' ? e : e.mot?.toLowerCase().includes(inputText)
        );
    });
    const filteredDataDesign = datas[0].Design.filter((e) => {
        return(
            inputText === '' ? e : e.mot?.toLowerCase().includes(inputText)
        );
    });
    const filteredDataPrint = datas[0].Print.filter((e) => {
        return(
            inputText === '' ? e : e.mot?.toLowerCase().includes(inputText)
        );
    });
    const filteredDataReseaux = datas[0].Reseaux.filter((e) => {
        return(
            inputText === '' ? e : e.mot?.toLowerCase().includes(inputText)
        );
    });
    const filteredDataAlcool = datas[0].Alcool.filter((e) => {
        return(
            inputText === '' ? e : e.mot?.toLowerCase().includes(inputText)
        );
    });
    const filteredDataPhotographies = datas[0].Photographies.filter((e) => {
        return(
            inputText === '' ? e : e.mot?.toLowerCase().includes(inputText)
        );
    });



    return (
        <>
            <section className="savoir-faire">
                <p>Tu peux filtrer un mot clef ici :</p>
                <div className="searchbar">
                <ThemeContext.Consumer>
                    {theme => (
                        theme.whichTheme === "white-content" ? 
                            <img src={searchIconLight} alt="Icône représentant une loupe concernant la recherche" />
                        : 
                            <img src={searchIconDark} alt="Icône représentant une loupe concernant la recherche" />
                        )}
                </ThemeContext.Consumer>
                    <input type="search" placeholder="Rechercher" onChange={inputLower} />
                </div>
                {errorInput && <p>Un caractère spécial est présent. Merci de le retirer.</p>}

                <section className="savoir-listing">
                    {window.innerWidth < 600 ?
                        <ThemeContext.Consumer>
                            {theme => (
                                theme.whichTheme === "white-content" ? 
                                    <img className="savoir-img-bg mobile" src={bgSavoirMobileLight} alt="Représentation d'une constellation"/>
                                : 
                                    <img className="savoir-img-bg mobile" src={bgSavoirMobileDark} alt="Représentation d'une constellation"/>
                            )}
                        </ThemeContext.Consumer>
                    : window.innerWidth >= 600 && window.innerWidth < 1201 ? 
                        <ThemeContext.Consumer>
                            {theme => (
                                theme.whichTheme === "white-content" ? 
                                    <img className="savoir-img-bg tablet" src={bgSavoirTabletLight} alt="Représentation d'une constellation"/>
                                : 
                                    <img className="savoir-img-bg tablet" src={bgSavoirTabletDark} alt="Représentation d'une constellation"/>
                            )}
                        </ThemeContext.Consumer>
                    : window.innerWidth >= 1201 ?
                        <ThemeContext.Consumer>
                        {theme => (
                            theme.whichTheme === "white-content" ? 
                                    <img className="savoir-img-bg desk" src={bgSavoirDeskLight} alt="Représentation d'une constellation"/>
                                : 
                                    <img className="savoir-img-bg desk" src={bgSavoirDeskDark} alt="Représentation d'une constellation"/>
                            )}
                        </ThemeContext.Consumer>
                    :
                        null
                    }
                    <ul className="savoir-list Web">
                        {filteredDataWeb.map((item, i) => (
                            <li className="savoir-listing-random" key={i}><LinkAnchor title={`Redirection vers ${item.url}`} key={i} to={item.url}>{item.mot}</LinkAnchor></li>
                        ))}
                    </ul>
                     <ul className="savoir-list Design">
                        {filteredDataDesign.map((item, i) => (
                            <li className="savoir-listing-random" key={i}><LinkAnchor title={`Redirection vers ${item.url}`} key={i} to={item.url}>{item.mot}</LinkAnchor></li>
                        ))}
                    </ul>
                    <ul className="savoir-list Print">
                        {filteredDataPrint.map((item, i) => (
                            <li className="savoir-listing-random" key={i}><LinkAnchor title={`Redirection vers ${item.url}`} key={i} to={item.url}>{item.mot}</LinkAnchor></li>
                        ))}
                    </ul>
                    <section className="section-sf-others">
                        <ul className="savoir-list Reseaux">
                            {filteredDataReseaux.map((item, i) => (
                                <li className="savoir-listing-random" key={i}><LinkAnchor title={`Redirection vers ${item.url}`} key={i} to={item.url}>{item.mot}</LinkAnchor></li>
                            ))}
                        </ul>
                        <ul className="savoir-list Alcool">
                            {filteredDataAlcool.map((item, i) => (
                                <li className="savoir-listing-random" key={i}><LinkAnchor title={`Redirection vers ${item.url}`} key={i} to={item.url}>{item.mot}</LinkAnchor></li>
                            ))}
                        </ul>
                        <ul className="savoir-list Photographies">
                            {filteredDataPhotographies.map((item, i) => (
                                <li className="savoir-listing-random" key={i}><LinkAnchor title={`Redirection vers ${item.url}`} key={i} to={item.url}>{item.mot}</LinkAnchor></li>
                            ))}
                        </ul>       
                    </section>  
                </section>
            </section>

        </>
    )
}