import { Link } from "react-router-dom"
import logoLight from '../../assets/Logo/logo-mobile-mode-white.svg'
export default function HeaderLightTheme() {
    return(
        <header>
            <Link to="/" title="Redirection vers la page principale du Studio Culotté.">
                <img src={logoLight} alt="Logo du Studio Culotté de couleur violette représentant un 'E' avec un accent en forme d'éclair." />
            </Link>  
        </header>
    )
}