import '../../css/Header/Header.css';
import { Resize } from "../Resize/Resize"
import { ThemeContext } from '../LightMode/ThemeContext'
import HeaderDarkTheme from "./HeaderDarkTheme"
import HeaderLightTheme from "./HeaderLightTheme"

export default function Header() {
    const {windowSize} = Resize();

    return (
        <>   
            {windowSize.innerWidth < 1201 ?
                <ThemeContext.Consumer>
                    {theme => (
                        theme.whichTheme === "white-content" ? 
                            <HeaderLightTheme />
                        : 
                            <HeaderDarkTheme />
                    )}
                </ThemeContext.Consumer>
            :
                null    
            } 

        </>
    );
}
