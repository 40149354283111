import { useContext } from "react";
import { Link } from "react-router-dom";
import { HashLink as LinkAnchor } from 'react-router-hash-link';
import { AuthContext } from "../../Context/AuthContext";
import "../../css/Sitemap/sitemap.css"

export default function Sitemap () {
  const [, setSlideIndex] = useContext(AuthContext);
    const modifyIndex = index => {
        setSlideIndex(index)
    }
    const switchIndex = (e) => {
        const targetName = e.target.classList
        switch(true) {
            default: 
                console.error(`⚠️ La class '${targetName}' ne correspond pas à la 'case' conçue. ⚠️`)
                break;
            case targetName.contains('danaides'): 
                modifyIndex(1)
                break;
            case targetName.contains('lombard'):
                modifyIndex(2)
                break;
            case targetName.contains('mongestine'):
                modifyIndex(3)
                break;
            case targetName.contains('ddr'):
                modifyIndex(4)
                break;
            case targetName.contains('lb'):
                modifyIndex(5)
                break;
            case targetName.contains('morillon'):
                modifyIndex(6)
                break;
            case targetName.contains('aromo'):
                modifyIndex(7)
                break;
            case targetName.contains('terravine'):
                modifyIndex(8)
                break;
            case targetName.contains('navigli'):
                modifyIndex(9)
                break;
            case targetName.contains('syrah'):
                modifyIndex(10)
                break;
            case targetName.contains('decante'):
                modifyIndex(11)
                break;
            case targetName.contains('edition'):
                modifyIndex(12)
                break;
            case targetName.contains('nbnc'):
                modifyIndex(13)
                break;
            case targetName.contains('vif'):
                modifyIndex(14)
                break;
            case targetName.contains('cdv'):
                modifyIndex(15)
                break;
            case targetName.contains('vlm'):
                modifyIndex(16)
                break;
        } 
    }
    return (
      <>
        <main className="sitemap">
          <h1>Plan de site</h1>
          <h2>Pages :</h2>
          <ul>
            <li><Link to="/">Page d'accueil ;</Link></li>
            <li><Link to="/realisation">Les Réalisations ;</Link></li>
            <li><Link to="/savoir">Savoir-faire ;</Link></li>
            <li><Link to="/creation">Étapes de création ;</Link></li>
            <li><Link to="/studio">Le Studio.</Link></li>
          </ul>
          <h2>Nos Savoir-faire expliqués :</h2>
          <ul>
            <li><Link to="/savoir/direction-artistique">Direction artistique pour un domaine ;</Link></li>
            <li><Link to="/savoir/site-internet">Créer un site Internet différent ;</Link></li>
            <li><Link to="/savoir/logo-identite-visuelle">Logo et identité visuelle ;</Link></li>
            <li><Link to="/savoir/habillage-bouteille">Habillage de bouteille ;</Link></li>
            <li><Link to="/savoir/illustration">Illustration ;</Link></li>
            <li><Link to="/savoir/cartographie-vignoble">Cartographie de vignoble ;</Link></li>
            <li><Link to="/savoir/print-communication">Le print en communication ;</Link></li>
            <li><Link to="/savoir/lettres">Des lettres, rien que des lettres ;</Link></li>
            <li><Link to="/savoir/communaute">Communauté sur la toile ;</Link></li>
            <li><Link to="/savoir/ux-ui">UX/UI ;</Link></li>
            <li><Link to="/savoir/photo">Photo ;</Link></li>
            <li><Link to="/savoir/biere-vin">Bières & vins.</Link></li>
          </ul>
          <h2>Nos Réalisations :</h2>
          <ul>
            <li onClick={switchIndex}><Link to="/realisation" className="danaides">Brasserie les Danaïdes</Link></li>
            <li onClick={switchIndex}><Link to="/realisation" className="lombard">Domaine Lombard</Link></li>
            <li onClick={switchIndex}><Link to="/realisation" className="mongestine">Domaine de la Mongestine</Link></li>
            <li onClick={switchIndex}><Link to="/realisation" className="ddr">Distillerie du Rhône</Link></li>
            <li onClick={switchIndex}><Link to="/realisation" className="lb">Domaine Lionel Brenier</Link></li>
            <li onClick={switchIndex}><Link to="/realisation" className="morillon">Château Morillon</Link></li>
            <li onClick={switchIndex}><Link to="/realisation" className="aromo">Aromo</Link></li>
            <li onClick={switchIndex}><Link to="/realisation" className="terravine">Terra Vine Agency</Link></li>
            <li onClick={switchIndex}><Link to="/realisation" className="navigli">Navigli</Link></li>
            <li onClick={switchIndex}><Link to="/realisation" className="syrah">La Petite Serine</Link></li>
            <li onClick={switchIndex}><Link to="/realisation" className="decante">Le Décanté</Link></li>
            <li onClick={switchIndex}><Link to="/realisation" className="edition">Mieux Comprendre le (Vin) Bio</Link></li>
            <li onClick={switchIndex}><Link to="/realisation" className="nbnc">Ni Bu Ni Connu</Link></li>
            <li onClick={switchIndex}><Link to="/realisation" className="vif">Vif Agence de vins</Link></li>
            <li onClick={switchIndex}><Link to="/realisation" className="cdv">Cartographie de vignobles</Link></li>
            <li onClick={switchIndex}><Link to="/realisation" className="vlm">Verre le monde</Link></li>
          </ul>
          <h2>Savoir-faire :</h2>
          <ul>
            <li><LinkAnchor to="/savoir/lettres">Typographie</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/print-communication">Édition</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/habillage-bouteille">Étiquettes</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/logo-identite-visuelle">Identité de marque</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/logo-identite-visuelle#anchor-perception">Pictogrammes</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/logo-identite-visuelle">Branding</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/logo-identite-visuelle#anchor-perception">Charte Graphique</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/print-communication#anchor-human">Livres</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/logo-identite-visuelle">Logo</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/print-communication#anchor-human">Couverture de livres</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/print-communication#anchor-human">Couverture de magazines</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/lettres#anchor-maquettage">Maquettage</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/print-communication#anchor-human">Brochures</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/lettres#anchor-maquettage">Flyers</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/photo">Photographie</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/photo">Packshot</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/photo">Mockup</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/ux-ui">Parcours utilisateur</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/print-communication">Dépliants</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/cartographie-vignoble">Cartographie</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/cartographie-vignoble">Carte de vignoble</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/lettres#anchor-maquettage">Présentation PDF</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/illustration">Illustration</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/site-internet">Site internet</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/site-internet#anchor-site">Site vitrine</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/site-internet#anchor-site">Site e-commerce</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/site-internet#anchor-unique">React</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/site-internet">JavaScript</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/site-internet">Base de données</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/site-internet">HTML/CSS</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/site-internet#anchor-site">Site dynamique</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/ux-ui">Animation web/intéraction web</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/print-communication#anchor-format">Mise au format</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/ux-ui">Wireframe</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/ux-ui">Sitemap</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/direction-artistique">Direction artistique</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/biere-vin">Bières & Vins</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/print-communication">Design éditorial</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/print-communication">Kakémono</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/communaute">Réseaux sociaux</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/communaute#anchor-creation-contenu">Création de contenu</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/site-internet#anchor-engagement">SEO Friendly</LinkAnchor></li>
            <li><LinkAnchor to="/savoir/site-internet#anchor-engagement">Respect des bonnes pratiques web (opquast)</LinkAnchor></li>
          </ul>
        </main>
      </>
    );
  }