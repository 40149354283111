import '../../css/Savoir-faire/GameSC.css';
export default function GameSC() {
    return(
        <>  
            <section className="game">
                <h2>Wine Game (jeu-vidéo du vin)</h2>
                <p>Mets-toi dans la peau d'une vraie grappe de raisin et évite les maladies, insectes et l'oïdium ! (tout ça sans pesticides)</p>
                <p>Le jeu n'est accessible que sur ordinateur. Pour pouvoir y jouer, il faut se servir de la touche "Espace".</p>
                <a target="_blank" rel="noreferrer" href="https://game.le-studio-culotte.com/"><button>Commencer le jeu</button></a>
            </section>
        </>
    )
}