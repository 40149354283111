import '../../css/Savoir-faire/Competences.css';
export default function Competences () {
    return(
        <>
            <section className="competences">
                <h2>Mais on sait aussi faire ça :</h2>
                <p>Et on n'a pas besoin de te l'apprendre !</p>
                
                <article className="competences-1">
                    <img src="https://media.giphy.com/media/l3q2wCnKRThy8ltxS/giphy.gif" alt="" />
                    <p>Doser</p>
                </article>
                <article className="competences-2">
                    <img src="https://media.giphy.com/media/l2Sq0ogljPAubE2nS/giphy.gif" alt="" />
                    <p>Déguster</p>
                </article>
                <article className="competences-3">
                    <img src="https://media.giphy.com/media/k5DMbZaSpF21cvWIue/giphy.gif" alt="" />
                    <p>Rassembler</p>
                </article>
                <article className="competences-4">
                    <img src="https://media.giphy.com/media/WTdOnTQJwTHmhifwGE/giphy.gif" alt="" />
                    <p>Assembler</p>
                </article>
                <article className="competences-5">
                    <img src="https://media.giphy.com/media/BPJmthQ3YRwD6QqcVD/giphy.gif" alt="" />
                    <p>Trinquer</p>
                </article>
                <article className="competences-6">
                    <img src="https://media.giphy.com/media/3ohhwId7GGaSbawn7O/giphy.gif" alt="" />
                    <p>Grumer</p>
                </article>
                <article className="competences-7">
                    <img src="https://media.giphy.com/media/iebqkcA0GBBjHFKROw/giphy.gif" alt="" />
                    <p>Pétiller</p>
                </article>
                <article className="competences-8">
                    <img src="https://media.giphy.com/media/iWTnpqPv2N1PG/giphy.gif" alt="" />
                    <p>Désaltérer</p>
                </article>
            </section>
        </>
    )

}