import '../../../css/Navigation/Links.css';
import { Link } from "react-router-dom";
import { HashLink as LinkAnchor } from 'react-router-hash-link';
import { Resize } from "../../Resize/Resize"
import { ThemeContext } from '../../LightMode/ThemeContext'
import bgMenuMobileLight from "../../../assets/Background/background-menu-mobile-jour.svg"
import bgMenuMobileDark from "../../../assets/Background/background-menu-mobile-nuit.svg"
import bgMenuTabletLight from "../../../assets/Background/background-menu-tablet-jour.svg"
import bgMenuTabletDark from "../../../assets/Background/background-menu-tablet-nuit.svg"
import bgMenuDeskLight from "../../../assets/Background/background-menu-desk-jour.svg"
import bgMenuDeskDark from "../../../assets/Background/background-menu-desk-nuit.svg"
import logoDark from '../../../assets/Logo/logo-mobile-mode-dark.svg'
import logoLight from '../../../assets/Logo/logo-mobile-mode-white.svg'

export default function Links (props) {
    const {windowSize} = Resize();

    return(
        <section className={windowSize.innerWidth < 1201 ? "nav-links" : "nav-links desk"}>
            {windowSize.innerWidth < 1201 ? 
                <ThemeContext.Consumer>
                {theme => (
                    theme.whichTheme === "white-content" ? 
                        <Link to="/" title="Redirection vers la page d'accueil du Studio Culotté."><img onClick={() => props.isMobile && props.closeMobileMenu() && window.scrollTo({top: 0, left: 0, behavior: 'smooth'})} className="nav-links-logo" src={logoLight} alt="Logo du Studio Culotté" /></Link>
                    : 
                        <Link to="/" title="Redirection vers la page d'accueil du Studio Culotté."><img onClick={() => props.isMobile && props.closeMobileMenu() && window.scrollTo({top: 0, left: 0, behavior: 'smooth'})} className="nav-links-logo" src={logoDark} alt="Logo du Studio Culotté" /></Link>
                    )}
                </ThemeContext.Consumer>
                :
                null
            }      
            {windowSize.innerWidth < 600 ?
                <ThemeContext.Consumer>
                {theme => (
                    theme.whichTheme === "white-content" ? 
                    <img className="nav-links-bg mobile" src={bgMenuMobileLight} alt="Arrière-plan du Studio Culotté représentant une constellation." />
                    : 
                    <img className="nav-links-bg mobile" src={bgMenuMobileDark} alt="Arrière-plan du Studio Culotté représentant une constellation." />
                    )}
                </ThemeContext.Consumer> 
            : windowSize.innerWidth >= 600 && windowSize.innerWidth < 1201 ?
                <ThemeContext.Consumer>
                {theme => (
                    theme.whichTheme === "white-content" ? 
                    <img className="nav-links-bg mobile" src={bgMenuTabletLight} alt="Arrière-plan du Studio Culotté représentant une constellation." />
                    : 
                    <img className="nav-links-bg mobile" src={bgMenuTabletDark} alt="Arrière-plan du Studio Culotté représentant une constellation." />
                    )}
                </ThemeContext.Consumer> 
            : windowSize.innerWidth >= 1201 ?
                <ThemeContext.Consumer>
                {theme => (
                    theme.whichTheme === "white-content" ? 
                    <img className="nav-links-bg desk" src={bgMenuDeskLight} alt="Arrière-plan du Studio Culotté représentant une constellation." />
                    : 
                    <img className="nav-links-bg desk" src={bgMenuDeskDark} alt="Arrière-plan du Studio Culotté représentant une constellation." />
                    )}
                </ThemeContext.Consumer> 
            :
                null
            }
            {windowSize.innerWidth < 1201 ? 
                <ul className="nav-links-ul mobile">
                    <li onClick={() => props.isMobile && props.closeMobileMenu() && window.scrollTo({top: 0, left: 0, behavior: 'smooth'}) && window.location.reload()}><Link to="/savoir" title="Redirection vers la page savoir-faire du Studio Culotté.">SAVOIR-FAIRE</Link></li>
                    <li onClick={() => props.isMobile && props.closeMobileMenu() && window.scrollTo({top: 0, left: 0, behavior: 'smooth'}) && window.location.reload()}><Link to="/creation" title="Redirection vers la page des étapes de création du Studio Culotté.">ÉTAPES DE CRÉATION</Link></li>
                    <li onClick={() => props.isMobile && props.closeMobileMenu() && window.scrollTo({top: 0, left: 0, behavior: 'smooth'}) && window.location.reload()}><Link to="/studio" title="Redirection vers la présentation du Studio Culotté.">LE STUDIO</Link></li>
                    <li onClick={() => props.isMobile && props.closeMobileMenu()}><LinkAnchor to="/#faq" title="Ancre de redirection vers la FAQ de la page d'accueil.">Faq</LinkAnchor></li>
                    <li><a href="https://agence-communication-vin-et-spiritueux.le-studio-culotte.com/" title="Ancre de redirection vers les différents moyens de contact.">Prendre RDV</a></li>
                </ul>
            :
                <ul className="nav-links-ul desk">
                    <li onClick={() => props.isDesk && props.closeMobileMenu() && window.scrollTo({top: 0, left: 0, behavior: 'smooth'}) && window.location.reload()}><Link to="/savoir" title="Redirection vers la page savoir-faire du Studio Culotté.">SAVOIR-FAIRE</Link></li>
                    <li onClick={() => props.isDesk && props.closeMobileMenu() && window.scrollTo({top: 0, left: 0, behavior: 'smooth'}) && window.location.reload()}><Link to="/creation" title="Redirection vers la page des étapes de création du Studio Culotté.">ÉTAPES DE CRÉATION</Link></li>
                    <li onClick={() => props.isDesk && props.closeMobileMenu() && window.scrollTo({top: 0, left: 0, behavior: 'smooth'}) && window.location.reload()}><Link to="/realisation" title="Redirection vers la page des réalisations du Studio Culotté.">LES RÉALISATIONS</Link></li>
                    <li onClick={() => props.isDesk && props.closeMobileMenu() && window.scrollTo({top: 0, left: 0, behavior: 'smooth'}) && window.location.reload()} className="nav-links-desk-studio"><Link to="/studio" title="Redirection vers la présentation du Studio Culotté.">LE STUDIO</Link></li>
                    <li className="nav-links-desk-anchor" onClick={() => props.isDesk && props.closeMobileMenu()}><LinkAnchor to="/#faq" title="Ancre de redirection vers la FAQ de la page d'accueil.">Faq</LinkAnchor></li>
                    <li><a href="https://agence-communication-vin-et-spiritueux.le-studio-culotte.com/" title="Ancre de redirection vers les différents moyens de contact.">Prendre RDV</a></li>
                </ul>
            }   
        </section>
    )
}