import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import ThemeContextWrapper from './components/LightMode/ThemeContextWrapper'
import App from './app/App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeContextWrapper>
    <Router basename='/'>
      <App />
    </Router>
  </ThemeContextWrapper>
  
);
reportWebVitals();
